/* Font Styles */
@font-face {
	font-family: "Poppins";
	src: url("../src/assets/fonts/Poppins-Regular.ttf");
	font-style: normal;
}

@font-face {
	font-family: "Poppins Light";
	src: url("../src/assets/fonts/Poppins-Light.ttf");
	font-style: normal;
}

@font-face {
	font-family: "Poppins Bold";
	src: url("../src/assets/fonts/Poppins-SemiBold.ttf");
	font-style: normal;
}

@font-face {
	font-family: "Avenir";
	src: url("../src/assets/fonts/AvenirNextLTPro-Regular.otf");
}

@font-face {
	font-family: "Avenir Bold";
	src: url("../src/assets/fonts/AvenirNextLTPro-Bold.otf");
}

@font-face {
	font-family: "Open Sans Regular";
	src: url("../src/assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
	font-family: "Open Sans Bold";
	src: url("../src/assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
	font-family: "Mini Serif Bold";
	src: url("../src/assets/fonts/MINI\ Serif-Bold.ttf");
}

@font-face {
	font-family: "Mini Sans Serif Regular";
	src: url("../src/assets/fonts/MINISansSerif-Regular.ttf");
}

@font-face {
	font-family: "Mini Sans Serif Bold";
	src: url("../src/assets/fonts/MINISansSerif-Bold.ttf");
}

@font-face {
	font-family: "BMW Regular";
	src: url("../src/assets/fonts/BMWTypeNext-Regular.ttf");
}

@font-face {
	font-family: "BMW Bold";
	src: url("../src/assets/fonts/BMWTypeNext-Bold.ttf");
}

@font-face {
	font-family: "BMW Motorrad Regular";
	src: url("../src/assets/fonts/BMWMotorrad-Regular.ttf");
}

@font-face {
	font-family: "BMW Motorrad Bold";
	src: url("../src/assets/fonts/BMWMotorrad-Bold.ttf");
}

@font-face {
	font-family: "Montserrat Regular";
	src: url("../src/assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
	font-family: "Montserrat Bold";
	src: url("../src/assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
	font-family: "Mercedes Benz Regular";
	src: url("../src/assets/fonts/MbCorpoSTextOffice.ttf");
}

@font-face {
	font-family: "Mercedes Benz Heading";
	src: url("../src/assets/fonts/MbCorpoATitleCondOffice.ttf");
}

@font-face {
	font-family: "Mercedes Benz Bold";
	src: url("../src/assets/fonts/MBCorpoSTextOffice-Bold\ 3.ttf");
}

@font-face {
	font-family: "Mazda Type Bold";
	src: url("../src/assets/fonts/Mazda-Type-Bold.ttf");
}

@font-face {
	font-family: "Mazda Type Regular";
	src: url("../src/assets/fonts/Mazda-Type-Regular.ttf");
}

@font-face {
	font-family: "Suzuki Pro Bold";
	src: url("../src/assets/fonts/SuzukiPROBold.ttf");
}

@font-face {
	font-family: "Suzuki Pro Regular";
	src: url("../src/assets/fonts/SuzukiPRORegular.ttf");
}

@font-face {
	font-family: "Changan Regular";
	src: url("../src/assets/fonts/ChanganTitilliumWeb-Regular.ttf");
}

@font-face {
	font-family: "Changan Bold";
	src: url("../src/assets/fonts/ChanganTitilliumWeb-Bold.ttf");
}