.applied-filter {
	border-radius: 4px;
	width: auto;
	border: 1px solid var(--primary);
}

.applied-filter span {
	padding: 6px 10px;
	font-family: var(--font-light);
	font-size: 14px;
}

.applied-filter svg {
	margin-right: 7px;
	height: 100%;
	padding-bottom: 6px;
	cursor: pointer;
}

@media screen and (max-width: 768.9px) {
	.applied-filter {
		display: inline-block;
		margin: 0 15px 10px 0px
	}
}
