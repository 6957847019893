.MuiSnackbar-root {
	top: 0 !important;
	left: 0 !important;
	right: auto;
	background-color: var(--primary);
	color: var(--inchcape-white) !important;
	width: 100vw;
	max-height: 60px;
	z-index: 9999999 !important;
}

.notification.success {
	background-color: var(--notif-success);
}

.notification.error {
	background-color: var(--notif-error);
}

.notification {
	padding: 6px 16px;
}

.notification span {
	font-family: var(--font-normal);
	font-size: 16px;
}

.notification svg {
	width: 22px;
	height: 22px;
}

.notification svg {
	margin-right: 12px !important;
}

.notification .close-btn {
	cursor: pointer;
}
