.bookings-cont {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.bookings-cont h3 {
	font-size: 26px;
	color: var(--main-grey);
	font-family: var(--font-heading);
}

.bookings-cont .bookings {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.bookings-cont button {
	align-self: flex-end;
}

.bookings-cont .no-booking {
	font-family: var(--font-normal);
	font-size: 16px;
}

@media screen and (max-width: 576.9px) {
	.bookings-cont button {
		align-self: normal;
	}
}

