.schedule-cont {
	display: flex;
	gap: 60px;
	margin-top: 16px;
}

.filters {
	width: 30%;
	min-width: 230px;
	max-width: 515px;
	padding: 25px;
}

.schedule {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 768.9px) {
	.schedule-cont {
		padding: 20px;
	}
}
