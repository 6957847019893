.multiselect .MuiFormLabel-root.MuiInputLabel-root {
	line-height: 1;
	top: -5px;
	left: -10px;
}

.multiselect .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
	color: var(--primary);
}

.multiselect .MuiFormLabel-root.MuiInputLabel-root,
.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
	font-family: var(--font-light);
	color: var(--main-grey);
	font-size: 14px;
	padding-left: 5px;
}

@media screen and (max-width: 576.9px) {
	.MuiPaper-root.MuiPaper-elevation .MuiButtonBase-root.MuiCheckbox-root {
		color: var(--primary) !important;
	}
}
