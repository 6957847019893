.tooltip-container button {
	font-family: var(--font-bold);
	font-size: 1rem;
	padding: 0.563rem 0.625rem;
	border-radius: 0;
	color: var(--tooltip-button-color);
	background-color: var(--tooltip-button-background-color);
	border-color: var(--tooltip-button-border-color);
	border-radius: var(--tooltip-button-border-radius);
	text-transform: none;
}

.tooltip-container button:hover {
	color: var(--tooltip-button-hover-color);
	background-color: var(--tooltip-button-hover-background-color);
	border-color: var(--tooltip-button-hover-border-color);
}

.tooltip-container button div {
	gap: 15px;
}

.tooltip-container button svg {
	width: 25px;
	height: 25px;
}

.mobile-tooltip {
	display: none;
}

@media screen and (max-width: 768.9px) {
	.tooltip-container {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.tooltip-container button {
		width: 90%;
		justify-content: center;
		display: flex;
		text-transform: none;
	}

	.mobile-tooltip {
		display: block;
		width: 20px;
		height: 20px;
	}

	.mobile-tooltip svg {
		width: 100%;
		height: 100%;
		margin-bottom: 8px;
		fill: var(--tooltip-background-color);
	}

	.tooltip-container .MuiTooltip-tooltip {
		margin-top: 10px !important;
	}
}
