.vehicle-success {
	display: flex;
	flex-direction: column;
	gap: 48px;
}

.vehicle-success h3 {
	font-family: var(--font-bold);
	font-size: 30px;
	color: var(--primary);
	margin-bottom: 0;
}

.vehicle-success .reserve-info {
	display: flex;
	gap: 76px;
	/* justify-content: space-between; */
}

.vehicle-success .reserve-info .col-one,
.vehicle-success .reserve-info .col-two {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.vehicle-success .reserve-info .col-one > p,
.vehicle-success .reserve-info .col-two > p {
	font-family: var(--font-bold);
	font-size: 18px;
	color: var(--main-grey);
	margin-bottom: 0;
	text-wrap: nowrap;
}

.vehicle-success .reserve-info .col-one div,
.vehicle-success .reserve-info .col-two div {
	display: flex;
	align-items: center;
	gap: 14px;
}

.vehicle-success .reserve-info .col-one span,
.vehicle-success .reserve-info .col-two span {
	color: var(--booking-success);
	font-family: var(--font-normal);
	font-size: 16px;
}

.vehicle-success .reserve-info .col-one svg,
.vehicle-success .reserve-info .col-two svg {
	color: var(--primary);
	width: 20px;
	height: 20px;
}

.vehicle-success .buttons button {
	width: 170px;
}

@media screen and (max-width: 415px) {
	.vehicle-success .buttons {
		flex-direction: column;
	}
}

@media screen and (max-width: 640.9px) {
	.vehicle-success .reserve-info {
		flex-direction: column;
		gap: 30px;
	}
}

@media screen and (max-width: 768.9px) {
	.vehicle-success .reserve-info {
		gap: 30px;
	}

	.vehicle-success {
		width: 100%;
	}

	.vehicle-success button {
		width: auto !important;
		padding: 10px 15px;
		min-width: 120px;
	}
}
