.text-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	width: 100%;
	height: 100%;
	padding: 0 5px;
	background: #f7f7f7;
	box-shadow: 4px 4px 4px #0000001f;
}

.text-col span {
	color: var(--main-grey);
	font-size: 14px;
	text-align: center;
}

.text-col .heading {
	font-family: var(--font-bold);
}

.text-col .text {
	font-family: var(--font-normal);
}
