.MuiFormControl-root.MuiTextField-root.date-picker {
	width: 220px !important;
}

.date-picker .MuiInputBase-input.MuiOutlinedInput-input {
	text-transform: uppercase !important;
	color: var(--primary) !important;
	font-family: var(--font-normal) !important;
	font-size: 16px !important;
	cursor: default;
}

.MuiPickersCalendarHeader-label,
.MuiTypography-root.MuiDatePickerToolbar-title {
	text-transform: capitalize !important;
}

.date-picker .MuiSvgIcon-root {
	color: var(--primary);
}

.date-picker .MuiOutlinedInput-notchedOutline,
.date-picker .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.date-picker .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: var(--primary) !important;
}
