.schedule-header {
	font-family: var(--font-normal);
	color: var(--main-grey);
	margin-bottom: 43px;
}

.schedule-header-wrapper {
	margin-bottom: 30px;
}

.schedule-header p {
	margin-bottom: 8px;
	font-size: 18px;
}

.schedule-vehicle-wrapper {
	/* border: 1px solid red; */
	/* overflow-y: auto; */
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.schedule-vehicle-wrapper .no-result {
	font-family: var(--font-normal);
	font-size: 16px;
	color: var(--main-grey);
}

.schedule-header .date-selection {
	display: flex;
	align-items: center;
	gap: 10px;
}

.schedule-header .date-selection svg {
	width: 24.22px;
	height: 24.22px;
	fill: var(--primary);
	cursor: pointer;
}

.schedule-header .date-selection span.inactive > svg {
	opacity: 0.5;
	cursor: auto;
}

.schedule-header .mobile-filters {
	display: none;
	margin-bottom: 30px;
}

.schedule-header .mobile-filters span {
	font-size: 16px;
	color: var(--heading);
	font-family: var(--font-light);
}

.schedule-header .mobile-filters h3 {
	color: #333333;
	font-size: 24px;
	font-family: var(--font-heading);
}

.schedule-header .mobile-filters > div {
	display: flex;
	gap: 12px;
	margin-top: 20px;
}

.schedule-header .mobile-filters > div > * {
	width: 50%;
}

.schedule-header .mobile-filters > div > button {
	border-radius: 0;
}

.schedule-header .mobile-filters > div > label {
	display: none;
}

.schedule-header-wrapper .mobile-results {
	display: none;
}

.applied-mobile {
	display: none;
}

@media screen and (max-width: 768.9px) {
	.schedule-vehicle-wrapper {
		padding-bottom: 0;
	}

	.schedule-header-wrapper .order-dropdown {
		display: none;
	}

	.schedule-header > div {
		flex-direction: column;
	}

	.applied-mobile {
		display: block;
		flex-direction: unset;
	}

	.schedule-header .mobile-filters {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.schedule-header p {
		margin-bottom: 20px;
	}

	.MuiFormControl-root.MuiTextField-root.date-picker {
		margin-left: 2rem;
	}
}

@media screen and (max-width: 576.9px) {
	.schedule-header-wrapper .desktop-results {
		display: none;
	}

	.schedule-header-wrapper .mobile-results {
		display: block;
		font-size: 24px;
	}

	.schedule-header .mobile-filters .MuiInputBase-root.MuiInput-root:before {
		border-bottom: 1px solid var(--primary) !important;
	}

	.schedule-header .mobile-filters .MuiFormLabel-root.MuiInputLabel-root {
		color: var(--primary) !important;
		font-family: var(--font-bold);
		font-size: 14px;
		line-height: 1rem !important;
	}

	.schedule-header .mobile-filters .MuiSvgIcon-root.MuiSelect-icon.Mui-disabled {
		color: var(--primary);
		top: calc(50% - 1.3rem);
	}

	.applied-mobile {
		display: block;
		flex-direction: unset;
	}
}
