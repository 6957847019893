.order-dropdown .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before,
.order-dropdown .MuiInputBase-root.MuiInput-root:before,
.order-dropdown .MuiInputBase-root.MuiInput-root:after {
	border-bottom: 1px solid var(--inchcape-light-grey) !important;
}

.order-dropdown .MuiInputLabel-shrink.Mui-focused,
.order-dropdown .MuiInputLabel-shrink {
	color: var(--primary) !important;
	font-family: var(--font-normal) !important;
}

.order-dropdown .MuiSelect-select.MuiSelect-standard {
	font-family: var(--font-normal) !important;
	color: var(--main-grey) !important;
	font-size: 14px;
}

.order-dropdown .MuiFormLabel-root.MuiInputLabel-root {
	line-height: 2rem !important;
}

.order-dropdown .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus {
	background-color: var(--inchcape-white);
}

.order-dropdown .MuiFormLabel-root.MuiInputLabel-root {
	font-family: var(--font-normal);
	font-size: 14px;
	color: var(--main-grey);
}
