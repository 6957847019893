.manage-test-drive-form {
	padding: 40px 65px;
	border-radius: 4px;
	border: 1px solid var(--primary);
}

.manage-test-drive-form h4 {
	font-family: var(--font-bold);
	font-size: 22px;
	color: var(--main-grey);
	margin-bottom: 36px;
}

.manage-test-drive-form-inputs label {
	font-size: 18px;
}

.manage-test-drive-form .button {
	height: 45px;
}
