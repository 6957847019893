.manage-drive {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

@media screen and (max-width: 768px) {
  .manage-drive {
    gap: 0;
  }
}
