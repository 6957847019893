.filters-modal .MuiPaper-root.MuiDialog-paper {
	height: 80vh;
	width: 80vw;
	border-radius: 0;
}

.filters-modal .modal-header {
	height: 60px;
	box-shadow: 0 4px 4px 0 #00000040;
	font-family: var(--font-bold);
	font-size: 20px;
	align-items: center;
	justify-content: space-between;
}

.filters-modal .modal-header,
.filters-modal .modal-types,
.filters-modal .btn-group {
	padding: 18px 20px;
	display: flex;
}

.filters-modal .modal-header svg {
	width: 24px;
	height: 24px;
	color: var(--main-grey);
}

.filters-modal .modal-types {
	flex-direction: column;
	gap: 32px;
	font-family: var(--font-normal);
	color: var(--main-grey);
	margin-top: 20px;
}

.filters-modal .multiselect {
	width: 100%;
	margin: 0;
}

.filters-modal .btn-group {
	justify-content: space-between;
	gap: 10px;
	display: flex;
	flex-wrap: wrap;
}

.filters-modal .btn-group button {
	border-radius: 0;
	flex: 1;
	min-width: auto;
}
