.filters {
	flex-direction: column;
	gap: min(1.5vw, 36px);
	box-shadow: 0 4px 4px #00000040;
	min-height: 100%;
	display: flex;
}

.filters h5 {
	font-size: max(1.05vw, 20px);
	color: var(--heading);
}

.filters h5 {
	font-family: var(--font-heading);
}

.filters .heading p {
	font-family: var(--font-bold);
}

.filters .applied {
	display: flex;
	flex-wrap: wrap;
	column-gap: 22px;
	row-gap: 10px;
	min-height: 29px;
}

.filters .heading {
	padding: 5px 14px;
}

.filters .MuiCollapse-wrapperInner {
	padding: 25px 14px 5px 14px;
}

.filters .MuiCollapse-wrapperInner > div {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.filters .heading {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--primary);
	cursor: pointer;
}

.filters .heading svg {
	fill: var(--primary);
}

.filters .heading p {
	font-size: max(0.8vw, 16px);
	color: var(--primary);
}

.filters .see-more {
	color: var(--primary);
	font-family: var(--font-light);
	font-size: max(0.7vw, 14px);
	margin-top: 30px;
	cursor: pointer;
}

.filters .MuiFormControlLabel-root {
	margin: 0;
	height: 20px;
	display: flex;
	gap: 10px;
}

.filters .MuiFormControlLabel-root .MuiFormControlLabel-label {
	font-family: var(--font-light);
	font-size: max(0.75vw, 16px);
	color: var(--main-grey);
	line-height: 20px;
}

/* Checkbox */
.filters .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
	fill: var(--primary);
}

.filters .showrooms {
	margin-top: 15px;
}

@media screen and (max-width: 768.9px) {
	.filters {
		display: none;
	}
}
