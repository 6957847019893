.widget {
	padding: 40px 48px;
}

.widget .container {
	max-width: none;
}

.widget nav ul {
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid var(--primary);
	gap: 3px;
	margin: 0;
}

.widget nav ul li {
	display: flex;
	align-items: center;
	gap: 15px;
	list-style-type: none;
	padding: 8px 22px;
	cursor: pointer;
}

.widget nav ul li span {
	font-family: var(--font-bold);
	font-size: min(5vw, 20px);
	letter-spacing: 0.25px;
}

.widget nav ul li svg {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
}

.widget nav ul li.selected {
	background-color: var(--primary);
	color: var(--inchcape-white);
	border: 1px solid var(--primary);
	border-bottom-color: transparent;
	box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25) inset;
}

.widget nav ul li.unselected {
	color: var(--primary);
	border: 1px solid var(--primary);
}

.main-cont {
	position: relative;
}

@media screen and (max-width: 576.9px) {
	.widget {
		padding: 20px 0;
		position: relative;
	}

	.widget nav ul {
		padding: 0 10px;
		justify-content: center;
		gap: 10px;
	}

	.widget nav ul li {
		flex: 1;
		padding: 0.6rem;
	}

	.main-cont {
		position: static;
	}
}
