.vehicle-forms,
.vehicle-forms .terms,
.vehicle-forms .terms,
.vehicle-forms .terms span,
.vehicle-forms .btn-group {
	display: flex;
}

.vehicle-forms .inputs {
	column-gap: 1rem;
	row-gap: 38px;
}

.vehicle-forms .btn-group {
	gap: 20px;
}

.vehicle-forms {
	flex-direction: column;
	width: 100%;
}

.vehicle-forms .inputs {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.vehicle-forms .terms {
	margin-top: 25px;
	gap: 13px;
	align-items: center;
}

.vehicle-forms .terms .heading {
	font-family: var(--font-bold);
	font-size: max(0.75vw, 14px);
	color: var(--heading);
}

.vehicle-forms .terms span {
	font-size: max(0.56vw, 11px);
	font-family: var(--font-normal);
	color: var(--main-grey);
	gap: 8px;
}

.vehicle-forms .terms .terms-highlighted {
	display: inline;
	color: var(--primary);
	cursor: pointer;
	text-transform: lowercase;
	font-weight: 600;
}

.vehicle-forms .btn-group {
	justify-content: flex-end;
	margin-top: 35px;
	flex-wrap: wrap;
}

.vehicle-forms .error-message {
	color: var(--inchcape-red);
	font-family: var(--font-normal);
	font-size: 12px;
	margin-top: 20px;
}

.vehicle-forms h3 {
	display: none;
}

.vehicle-forms a {
	text-decoration: none;
	color: var(--primary);
}

@media screen and (max-width: 1400px) {
	.vehicle-forms .inputs {
		grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
	}
}

@media screen and (max-width: 576.9px) {
	.vehicle-forms .inputs {
		width: 100%;
		grid-template-columns: 1fr;
	}

	.vehicle-forms .btn-group {
		justify-content: center;
		flex-wrap: nowrap;
	}

	.vehicle-forms .btn-group > * {
		flex: 1;
		min-width: 0;
	}

	.vehicle-forms h3 {
		display: block;
		font-size: 18px;
		margin-bottom: 16px;
		font-family: var(--font-bold);
	}
}
