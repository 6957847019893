/* .vehicle-card {
  transform-style: preserve-3d;
  transition: all 0.8s ease;
}

.vehicle-info {
  position: relative;
  backface-visibility: hidden;
}

.vehicle-form {
  position: absolute;
  top: 0;
  width: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.cardFlip {
  transform: rotateY(180deg);
} */

.vehicle-info {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
}

.vehicle-info .car-info-box {
	display: flex;
	flex-direction: column;
	gap: 11px;
	align-items: center;
	/* border: 1px solid red; */
}

.vehicle-info .car {
	/* border: 1px solid orange; */
	width: 100%;
	min-height: 239px;
	max-height: 300px;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.vehicle-info .car img {
	width: 100%;
	height: auto;
}

.vehicle-info .card-label-title {
	font-family: var(--font-heading);
	font-size: 22px;
	color: var(--heading);
}

.vehicle-info .card-label {
	font-family: var(--font-normal);
	color: var(--heading);
	font-size: 22px;
}

.vehicle-info .location-time-box {
	/* border: 1px solid blue; */
	/* width: 376px;
  height: 196px; */
	display: flex;
	flex-direction: column;
	gap: 35px;
	flex: 1;
}

.vehicle-info .time-picker {
	gap: 27px;
}

.vehicle-info .time-box {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
	row-gap: 19px;
	column-gap: 12px;
	width: 100%;
}

.vehicle-info iframe {
	width: 100%;
	height: 295px;
}

.load-more-btn {
	background-color: transparent;
	color: var(--primary);
	border: none;
	font-family: var(--font-bold);
	font-size: 20px;
}

@media screen and (max-width: 1050.9px) {
	.vehicle-info > div:has(iframe) {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

/* @media screen and (max-width: 1200px) {
  .vehicle-info .car-info-box {
    align-items: center;
    width: 100%;
  }
} */

@media screen and (min-width: 1400px) {
	.vehicle-info .location-time-box {
		flex: 1;
	}

	.vehicle-info .car-info-box,
	.vehicle-info > div:has(iframe) {
		flex: 0.69;
	}
}

@media screen and (max-width: 1399.9px) {
	.vehicle-info .car-info-box {
		width: 100%;
	}

	.vehicle-info .car {
		height: auto;
		justify-content: center;
	}

	.vehicle-info .car img {
		width: 334px;
	}
}

@media screen and (max-width: 576.9px) {
	.vehicle-info .car-info-box {
		margin-bottom: 20px;
	}

	.vehicle-info .car,
	.vehicle-info .car img {
		width: 100%;
		height: auto;
	}

	.vehicle-info .time-picker {
		flex-direction: column;
	}

	.vehicle-info > div:has(iframe) {
		width: 100%;
	}
}
