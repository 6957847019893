.disclaimer .MuiPaper-root.MuiDialog-paper {
	max-width: none !important;
	width: min(90vw, 1298px);
	border-radius: 0 !important;
	padding: 70px;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.disclaimer.in-iframe .MuiPaper-root.MuiDialog-paper {
	max-height: 1000px;
}

.disclaimer h2 {
	font-family: var(--font-bold);
	font-size: 26px;
	color: var(--primary);
}

.disclaimer span {
	color: black;
	font-family: var(--font-normal);
	font-size: 12px;
}

.disclaimer button {
	align-self: flex-end;
}

@media screen and (max-width: 576.9px) {
	.disclaimer.in-iframe .MuiPaper-root.MuiDialog-paper {
		max-height: 1250px;
	}
}
