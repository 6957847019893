.date-card {
	border: 1px solid var(--primary);
	color: var(--main-grey);
	border-radius: 4px;
	height: 70px;
	width: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.date-card.selected {
	background: var(--primary);
	color: white;
}

.date-card.disabled {
	opacity: 0.5;
	cursor: auto;
	user-select: none;
}

.date-card .day {
	text-transform: uppercase;
}
