* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

p {
	margin: 0;
}

html,
body {
	background-color: #fff;
	width: 100%;
	height: 100%;
	min-height: 100vh;
}

button:focus-visible {
	outline: none;
}

:root {
	/* Default Colors */
	--inchcape-white: #ffffff;
	--primary: #16bae7;
	--primary-hover: #2dc6f0;
	--primary-disabled: #8f8f8f;
	--heading: #00233a;
	--main-grey: #39464f;
	--inchcape-dark-grey: #262626;
	--inchcape-light-grey: #858a8d80;
	--disable-primary: #858a8d;
	--disable-secondary: #39464f;
	--booking-success: #5c5c5c;
	--inchcape-indigo: #7887fb;
	--inchcape-yellow: #f2e500;
	--inchcape-red: #e40521;
	--inchcape-green: #b7cd00;
	--notif-success: #16bae7;
	--notif-error: #e40521;
	--tooltip-background-color: #9e61a4;
	--tooltip-text-color: #ffffff;
	--secondary: #ffffff;

	/* Button colors*/
	--primary-button-color: #ffffff;
	--primary-button-background-color: #16bae7;
	--primary-button-border-color: #16bae7;
	--primary-button-hover-color: #ffffff;
	--primary-button-hover-background-color: #16bae7;
	--primary-button-border-radius: 0px;
	--secondary-button-color: #16bae7;
	--secondary-button-background-color: #ffffff;
	--secondary-button-border-color: #16bae7;
	--secondary-button-hover-color: #ffffff;
	--secondary-button-hover-background-color: #16bae7;
	--secondary-button-border-radius: 0px;

	/* Tooltip button colors*/
	--tooltip-button-color: #16bae7;
	--tooltip-button-background-color: #ffffff;
	--tooltip-button-border-color: #16bae7;
	--tooltip-button-border-radius: 0px;
	--tooltip-button-hover-color: #ffffff;
	--tooltip-button-hover-background-color: #16bae7;
	--tooltip-button-hover-border-color: #16bae7;

	/* Fonts */
	--font-bold: "Poppins Bold";
	--font-normal: "Poppins";
	--font-light: "Poppins Light";
	--font-heading: "Poppins Bold";
}
