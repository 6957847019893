.header {
	display: flex;
	margin-bottom: 60px;
}

.header > span:nth-child(2) {
	font-family: var(--font-normal);
	font-size: 16px;
	color: var(--heading);
}

@media screen and (max-width: 768.9px) {
	.header {
		margin-bottom: 0;
	}

	.header > span {
		display: none;
	}
}
