.confirm .MuiPaper-root.MuiDialog-paper {
	padding: 40px 60px;
	border-radius: 0 !important;
	align-items: center;
	gap: 33px;
	text-align: center;
}

.confirm h4 {
	font-family: var(--font-bold);
	font-size: 34px;
	color: var(--heading);
	margin: 0;
}

.confirm .confirm-text {
	font-family: var(--font-normal);
	font-size: 18px;
	color: var(--main-grey);
}

.confirm .btn-group {
	display: flex;
	gap: 20px;
}

@media screen and (max-width: 576.9px) {
	.confirm .MuiPaper-root.MuiDialog-paper {
		padding: 40px 30px;
	}

	.confirm .btn-group {
		gap: 15px;
		width: 100%;
		justify-content: space-between;
	}

	.confirm .btn-group > * {
		width: 50%;
		min-width: 0;
	}
}
