.button {
	text-align: center;
	min-width: 170px;
	min-height: 45px;
	font-family: var(--font-bold);
}

.button:disabled,
.button:disabled:hover {
	color: var(--inchcape-white);
	background-color: var(--primary-disabled);
	border: 1px solid var(--primary-disabled);
	user-select: none;
	cursor: auto;
}
