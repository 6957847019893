.orderBy-modal .MuiPaper-root.MuiDialog-paper {
	height: 80vh;
	width: 70vw;
	border-radius: 0;
}

.orderBy-modal .modal-header {
	height: 60px;
	box-shadow: 0 4px 4px 0 #00000040;
	font-family: var(--font-bold);
	font-size: 20px;
	align-items: center;
	justify-content: space-between;
}

.orderBy-modal .modal-header,
.orderBy-modal .modal-types {
	padding: 18px 20px;
	display: flex;
}

.orderBy-modal .modal-header svg {
	width: 24px;
	height: 24px;
	color: var(--main-grey);
}

.orderBy-modal .modal-types {
	flex-direction: column;
	gap: 25px;
	font-family: var(--font-normal);
	color: var(--main-grey);
}
