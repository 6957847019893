/* Default text styling */
.lb-1,
.lb-2 {
	font-family: var(--font-heading);
	color: var(--heading);
}

.lb-1 {
	font-size: 45px;
}

.lb-2 {
	font-size: 26px;
}

/* Button default styling */
.primary-btn {
	cursor: pointer;
	color: var(--primary-button-color);
	background-color: var(--primary-button-background-color);
	border: 1px solid var(--primary-button-border-color);
	border-radius: var(--primary-button-border-radius);
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
	text-transform: uppercase; 
}

.secondary-btn {
	cursor: pointer;
	color: var(--secondary-button-color);
	background-color: var(--secondary-button-background-color);
	border: 1px solid var(--secondary-button-border-color);
	border-radius: var(--secondary-button-border-radius);
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
	text-transform: uppercase; 
}

.primary-btn:hover {
	background-color: var(--primary-button-hover-background-color);
	border: 1px solid var(--primary-button-hover-background-color);
	color: var(--primary-button-hover-color);
	transition: all 0.3s ease;
}

.secondary-btn:hover {
	background-color: var(--secondary-button-hover-background-color);
	color: var(--secondary-button-hover-color);
	transition: all 0.3s ease;
}

/* Tooltip */
.MuiTooltip-tooltip {
	font-family: var(--font-normal) !important;
	background-color: var(--tooltip-background-color) !important;
	color: var(--tooltip-text-color) !important;
}

.MuiTooltip-arrow {
	color: var(--tooltip-background-color) !important;
}

/* Checkbox */
.MuiButtonBase-root.MuiCheckbox-root {
	padding: 0 !important;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
	color: var(--primary);
}

/* Datepicker fonts */
.MuiButtonBase-root.MuiPickersDay-root,
.MuiPickersCalendarHeader-labelContainer,
.MuiPickersYear-yearButton,
.MuiInputBase-root.MuiOutlinedInput-root {
	font-family: var(--font-normal) !important;
}

/* Datepicker fonts */
.MuiPickersCalendarHeader-labelContainer,
.MuiPickersYear-yearButton {
	font-size: 14px !important;
}

/* Datepicker selected color */
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected:focus,
.MuiPickersYear-yearButton.Mui-selected:hover,
.MuiPickersYear-yearButton.Mui-selected,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
	background-color: var(--primary) !important;
}

/* Datepicker border color */
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: var(--inchcape-light-grey) !important;
}

/* Datepicker */
.MuiInputBase-root.MuiOutlinedInput-root {
	border-radius: 0 !important;
}

/* Datepicker */
.MuiOutlinedInput-notchedOutline {
	border-width: 0 0 1px 0 !important;
}

.MuiInputBase-input.MuiOutlinedInput-input {
	padding: 5px !important;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background: var(--inchcape-white);
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 35, 58, 0.1);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 35, 58, 0.1);
}
