.hour-btn {
	cursor: pointer;
	background-color: var(--inchcape-white);
	color: var(--main-grey);
	font-family: var(--font-normal);
	font-size: 13px;
	padding: 11px 17px;
	text-wrap: nowrap;
	border-radius: 10px;
	border: 1px solid var(--disable-secondary);
	box-shadow: 0px 2.9064px 2.9064px 0px rgba(0, 0, 0, 0.25);
}

.hour-btn:hover {
	background-color: var(--primary-button-background-color);
	color: var(--inchcape-white);
	transition: all 0.3s ease-in-out;
}

.hour-btn:disabled {
	cursor: default;
	pointer-events: none;
	background-color: var(--disable-primary);
	color: var(--inchcape-white);
}

.hour-btn.scheduled:disabled {
	background-color: var(--primary-button-background-color);
	color: var(--inchcape-white);
}
