.booking-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 29px;
	/* border: 1px solid red; */
	box-shadow: 0px 4px 4px 0px #00000040;
}

.booking-mobile img {
	width: 195px;
}

.booking-mobile .booking-info {
	display: flex;
	flex-direction: column;
}

.booking-info .booking-info span {
	font-family: var(--font-normal);
	font-size: 14px;
}

.booking-mobile .booking-info > span:nth-child(1) {
	font-family: var(--font-bold);
	margin-bottom: 4px;
}

.booking-mobile button {
	width: 50%;
	min-width: 0;
	height: 100%;
}
