.vehicle-card {
	padding: 20px 30px;
	border-radius: 4px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	/* margin-bottom: 30px; */
}

.vehicle-card:hover {
	outline: 2px solid var(--primary);
	transition: border 0.3s ease;
}

.vehicle-card.selected {
	outline: 2px solid var(--primary);
	transition: border 0.3s ease;
}

.vehicle-card .form-success.form {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.vehicle-card .form-success .form-heading {
	color: var(--heading);
}

.vehicle-card .form-success .form-heading h5,
.vehicle-card .form-success.form .model span,
.vehicle-card .form-success.success .model span {
	font-family: var(--font-bold);
}

.vehicle-card .form-success .form-heading h5 {
	font-size: max(1.3vw, 24px);
	display: inline;
}

.vehicle-card .form-success .form-heading span {
	font-family: var(--font-normal);
	font-size: max(1.05vw, 18px);
}

.vehicle-card .form-success .model {
	background: #f4f6f9;
}

.vehicle-card .form-success.form > div:not(.form-heading) {
	display: flex;
	gap: 44px;
}

.vehicle-card .form-success.form .model {
	width: 20%;
	min-width: 201px;
	height: 207.11px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.vehicle-card .form-success.form .model img {
	width: 160px;
	height: auto;
	margin-bottom: 2px;
}

.vehicle-card .form-success.form .model span {
	font-size: max(0.85vw, 15px);
	margin-bottom: 10.69px;
}

.vehicle-card .form-success.form .model p {
	font-size: max(0.65vw, 12px);
	font-family: var(--font-light);
}

.vehicle-card .form-success.success {
	padding: 12px 64px;
}

.vehicle-card .form-success.success > div {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 1rem;
}

.vehicle-card .form-success.success .model {
	width: 20%;
	min-width: 407px;
	height: 312px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.vehicle-card .form-success.success .model img {
	width: 246px;
}

.vehicle-card .form-success.success .model span {
	font-size: max(0.85vw, 15px);
	margin-bottom: 10.69px;
}

.vehicle-card .form-success.form .model .vehicle-model,
.vehicle-card .form-success.success .model .vehicle-model {
	display: none;
}

@media screen and (max-width: 1600px) {
	.vehicle-card .form-success.success .model {
		min-width: 277px;
	}
}

@media screen and (max-width: 992.9px) {
	.vehicle-card .form-success.form > div:nth-child(2),
	.vehicle-card .form-success.success > div {
		flex-direction: column;
	}

	.vehicle-card .form-success.form .model,
	.vehicle-card .form-success.success .model {
		width: 100%;
		height: auto;
	}

	.vehicle-card .form-success.form .terms {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 992.9px) {
	.vehicle-card .form-success.form .terms {
		flex-direction: column;
		align-items: flex-start;
	}
	.vehicle-card .form-success.success {
		padding: 12px;
	}
}

@media screen and (max-width: 576.9px) {
	.vehicle-card .form-success.form .form-heading,
	.vehicle-card .form-success.form .model .model-name,
	.vehicle-card .form-success.form .model > p,
	.vehicle-card .form-success.success .form-heading,
	.vehicle-card .form-success.success .model .model-name,
	.vehicle-card .form-success.success .model > p {
		display: none;
	}

	.vehicle-card .form-success.form .model img,
	.vehicle-card .form-success.success .model img {
		width: 100%;
	}

	.vehicle-card .form-success.form .model .vehicle-model,
	.vehicle-card .form-success.success .model .vehicle-model {
		display: flex;
		gap: 10px;
		margin-top: 1rem;
		align-items: center;
		font-size: 22px;
	}

	.vehicle-card .form-success.form .model .vehicle-model p,
	.vehicle-card .form-success.sucess .model .vehicle-model p {
		font-size: 22px;
	}

	.vehicle-card .form-success.form .model,
	.vehicle-card .form-success.success .model {
		background: none;
	}

	.vehicle-card .form-success.success {
		padding: 12px 0;
	}
}
