.booking {
  display: flex;
  gap: 25px;
  height: 125px;
}

.booking img {
  width: min(18%, 195.1px);
  height: auto;
  object-position: top;
  object-fit: contain;
}

.booking .table {
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0 4px 4px #00000040;
}
