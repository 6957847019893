.inquiry .MuiPaper-root.MuiDialog-paper {
	max-width: none !important;
	width: min(90vw, 455px);
	border-radius: 0 !important;
	padding: 20px 30px;
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.inquiry .inquiry-forms {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 25px;
	padding: 0 49px;
}

.inquiry h2 {
	font-family: var(--font-bold);
	font-size: 34px;
	color: var(--heading);
	margin-bottom: 0px;
	text-align: center;
}

.inquiry .close-icon {
	cursor: pointer;
	width: 36px;
	height: 36px;
}

.inquiry-forms .MuiTextField-root {
	width: 297px;
}

.inquiry-forms .MuiFormControl-root {
	width: 297px;
	height: auto;
}

.inquiry .terms {
	/* width: 297px; */
	gap: 15px;
	align-items: center;
}

.inquiry-forms .terms .heading {
	font-family: var(--font-bold);
	font-size: 14px;
	color: var(--primary);
	margin-bottom: 15px;
}

.inquiry-forms .terms .terms-highlighted {
	cursor: pointer;
	font-family: var(--font-normal);
	font-size: 11px;
	color: var(--primary);
	text-transform: lowercase;
	font-weight: 600;
}

.inquiry-forms .terms div span {
	font-family: var(--font-normal);
	font-size: 11px;
}

.inquiry-forms a {
	text-decoration: none;
	color: var(--primary);
}

@media screen and (max-width: 576.9px) {
	.inquiry .inquiry-forms {
		padding: 0;
	}
}
