.input-types {
	width: 100%;
}

.input-types .MuiInputBase-input.MuiInput-input,
.input-types .MuiFormLabel-root.MuiInputLabel-root,
.input-types .MuiFormLabel-root.MuiInputLabel-root {
	font-family: var(--font-normal) !important;
	color: var(--main-grey);
}

.input-types .MuiFormLabel-root.MuiInputLabel-root,
.input-types .MuiInputBase-input.MuiInput-input {
	font-size: 14px;
}

.input-types .MuiSelect-select.MuiInputBase-input.MuiInput-input:focus {
	background-color: var(--inchcape-white);
}

.input-types .MuiFormControl-root.MuiTextField-root,
.input-types .MuiFormControl-root {
	width: 100% !important;
}

.input-types .MuiInputBase-root.MuiInput-root.MuiSelect-root,
.input-types .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before,
.input-types .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: var(--inchcape-light-grey) !important;
}

.input-types .MuiInputLabel-shrink,
.input-types .MuiInputLabel-shrink.Mui-focused,
.input-types .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
	color: var(--primary) !important;
}

.input-types .Mui-focused,
.input-types .MuiInputBase-root.MuiInput-root,
.input-types .MuiInputBase-root.MuiInput-root:after,
.input-types .MuiInputBase-root.MuiInput-root.MuiSelect-root:after {
	border-bottom: 0;
}

.input-types .MuiInputBase-root.MuiInput-root:before,
.input-types .MuiInputBase-root.MuiInput-root.MuiSelect-root:before,
.input-types .MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
	border-bottom: 1px solid var(--inchcape-light-grey) !important;
}

.input-types
	.MuiInputBase-root.MuiInput-root.MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before {
	border-bottom: 2px solid var(--inchcape-light-grey);
}

.MuiButtonBase-root.MuiMenuItem-root,
.input-types .MuiSelect-select.MuiInputBase-input.MuiInput-input,
.input-types fieldset,
.input-types .MuiInputBase-root.MuiOutlinedInput-root {
	font-family: var(--font-normal) !important;
	font-size: 14px !important;
	color: var(--main-grey) !important;
	white-space: normal;
}

.input-types .MuiSelect-select.MuiInputBase-input.MuiInput-input {
	padding: 1px 0 5px;
}

.input-types .Mui-focused fieldset {
	border-color: var(--inchcape-light-grey) !important;
}

.input-types .MuiOutlinedInput-notchedOutline {
	font-size: 18px;
}

.input-types.error span {
	color: var(--inchcape-red);
	font-size: 12px;
	font-family: var(--font-light);
}

.input-types .MuiFormLabel-root.MuiInputLabel-root.Mui-error,
.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
	color: var(--main-grey) !important;
}

.input-types .MuiButtonBase-root.MuiMenuItem-root {
	font-family: var(--font-light) !important;
}

.input-types
	.MuiFormControl-root.MuiTextField-root.textarea
	.MuiInputBase-root.MuiOutlinedInput-root {
	padding: 12px 0 5px;
}

.input-types .MuiFormControl-root.MuiTextField-root.textarea .MuiFormLabel-root.MuiInputLabel-root {
	left: -12px;
}

.input-types.error .MuiInputBase-root.MuiInput-root::before,
.input-types.error .MuiInputBase-root:hover.MuiInput-root::before {
	border-bottom: 1px solid var(--inchcape-red) !important;
}
