.func-col,
.func-col.disabled:hover {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 25px 5px 0 5px;
	background: var(--primary-button-background-color);
	box-shadow: 0 4px 4px #00000040;
	cursor: pointer;
	color: var(--secondary);
}

.func-col.cancel,
.func-col.cancel.disabled:hover {
	background: var(--secondary-button-background-color);
	color: var(--secondary-button-color);
	border: 1px solid var(--secondary-button-border-color);
}

.func-col.disabled {
	opacity: 0.6;
	cursor: auto !important;
	user-select: none;
}

.func-col:hover {
	background-color: var(--primary-hover);
	transition: all 0.3s ease;
}

.func-col.cancel:hover {
	background-color: var(--secondary-button-hover-background-color);
	color: var(--secondary-button-hover-color);
}

.func-col span {
	width: min(6vw, 61px);
	height: min(6vw, 61px);
	border-radius: 50%;
	background: white;
	border: 1px solid var(--primary-button-background-color);
	position: absolute;
	top: -25%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.func-col.cancel span {
	border: 1px solid var(--secondary-button-border-color);
}

.func-col svg {
	padding: 0 !important;
	height: max(1vw, 20px);
	width: max(1vw, 20px);
	fill: var(--primary);
	background-color: transparent !important;
}

.func-col.cancel svg {
	fill: var(--secondary-button-border-color);
}

.func-col.reschedule svg {
	fill: var(--primary-button-background-color);
}

.func-col p {
	font-size: min(1.5vw, 16px);
	font-family: var(--font-bold);
	text-align: center;
}
