.info-modal .MuiPaper-root.MuiDialog-paper {
	max-width: none !important;
	width: min(90vw, 440px);
	border-radius: 0 !important;
	padding: 40px 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 25px;
}

.info-modal h2 {
	font-family: var(--font-bold);
	font-size: 34px;
	color: var(--heading);
	margin-bottom: 0px;
}

.info-modal span {
	text-align: center;
	font-family: var(--font-normal);
	font-size: 18px;
	color: var(--main-grey);
}
