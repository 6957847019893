.loader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #ffffff66;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
}

.loader .spinner-border {
	border-width: 5px;
	border-color: var(--primary);
	border-right-color: transparent !important;
}
